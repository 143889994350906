import {Elm} from './src/BarberWindow/Main.elm';

import { formatDistance } from "date-fns";

const firebase = require('firebase/app');
import * as firebaseui from 'firebaseui';
require('firebase/auth');
require('firebase/firestore');
require('firebase/functions');

const $appNode = document.getElementById('main');
const firebaseConfig = {
  apiKey: $appNode.dataset.apiKey,
  authDomain: $appNode.dataset.authDomain,
  databaseURL: $appNode.dataset.databaseURL,
  projectId: $appNode.dataset.projectId,
  storageBucket: $appNode.dataset.storageBucket,
  messagingSenderId: $appNode.dataset.messagingSenderId,
  appId: $appNode.dataset.appId,
};

const shopDocId = $appNode.dataset.shopDocId;

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const functions = firebase.functions();
const firestore = firebaseApp.firestore();
const auth = firebaseApp.auth();

const ui = new firebaseui.auth.AuthUI(auth);

var uiConfig = {
  signInSuccessUrl: '/admin/shop',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      const isNewUser = authResult.additionalUserInfo.isNewUser;

      if (isNewUser ) {
        const requestAccessToShop = functions.httpsCallable("requestAccessToShop");
        requestAccessToShop({
          shopUid: shopDocId
        }).then(result => {
          console.log("requestAccessToShop result", result)
        });
      }
      
      return false;
    }
  }
};

const shopsRef = firestore.collection("shops");

const elmApp = Elm.BarberWindow.Main.init({
  node: $appNode,
});

elmApp.ports.initFirebaseAuthUi.subscribe(() => {
  const setUp = () => {
    const el = document.getElementById("firebaseui-auth-container");
    if (el) {
      ui.start('#firebaseui-auth-container', uiConfig);
    }
    else {
      window.requestAnimationFrame(setUp);
    }
  }
  window.requestAnimationFrame(setUp);
});

elmApp.ports.getShop.subscribe(() => {
  const query = shopsRef.doc(shopDocId);
  query.onSnapshot(
    {
      next: snapshot => {
        const shopData = snapshot.data();
        shopData.uid = shopDocId;
        shopData.status = "unknown";
        elmApp.ports.shopData.send(shopData);
      }
    }
  );
});

elmApp.ports.updateShopMessage.subscribe(data => {
  const shopDocRef = shopsRef.doc(shopDocId);
  shopDocRef.update({
    shopMessage: data[0],
    shopPopupMessage: data[1]
  })
    .then(function () {
      updateShopHtml();
    })
    .catch(function (error) {
      elmApp.ports.firebaseError.send({
        errorMessage: error.message
      });
      console.error("Error updating document: ", error);
    });
});

elmApp.ports.updateAboutShopMarkdown.subscribe(data => {
  const shopDocRef = shopsRef.doc(shopDocId);
  shopDocRef.update({
    aboutShopMarkdown: data,
  })
    .then(function () {
      updateShopHtml();
    })
    .catch(function (error) {
      elmApp.ports.firebaseError.send({
        errorMessage: error.message
      });
      console.error("Error updating document: ", error);
    });
});

elmApp.ports.createNewUser.subscribe(data => {
  auth.createUserWithEmailAndPassword(data.email, data.password)
    .then((cred) => {
      firestore.collection("users")
        .doc(cred.user.uid)
        .set({})
        .then( () => {
          firestore.collection(`users/${cred.user.uid}/shops`).doc(data.shopId).set({signUp: true})
            .then( () => {
              elmApp.ports.newUserCreated.send({
                email: cred.user.email,
                displayName: cred.user.displayName,
                uid: cred.user.uid
              })
            });
        })
    })
    .catch((error) => {
      elmApp.ports.firebaseError.send({
        errorMessage: error.message
      });
      elmApp.ports.creatingNewUserError.send({
        errorMessage: error.message,
        errorCode: error.code
      })
      console.error(
        "Error creating a new user: ",
        error.code,
        error.message
      );
    });
});

elmApp.ports.loginUser.subscribe(data => {
  auth.signInWithEmailAndPassword(data.email, data.password)
    .then((userCredential) => {
      handleLogin(userCredential.user);
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      elmApp.ports.userLoginError.send({
        "errorCode": errorCode,
        "errorMessage": errorMessage
      });
      console.error("user login error", error.code, error.message);
    });
});

elmApp.ports.logoutUser.subscribe(() => {
  auth.signOut();
});

auth.onAuthStateChanged(user => {
  if (user) {
    handleLogin(user);
  }
  else {
    elmApp.ports.userLoggedOut.send(true);
  }
});


const handleLogin = user => {
  user.getIdTokenResult()
    .then(idTokenResult => {
      const isSuperDuper = idTokenResult.claims.superduper || false;
      const docRef = firestore.collection(`shops/${shopDocId}/users`).doc(user.uid);
      docRef.get().then(doc => {
        if(doc.exists) {
          const shopUserData = doc.data();
          elmApp.ports.userLoggedIn.send({
            email: user.email,
            displayName: user.displayName,
            uid: user.uid,
            superduper: isSuperDuper,
            hasAccess: shopUserData.hasAccess || isSuperDuper,
          });
        }
        else {
          elmApp.ports.userLoggedIn.send({
            email: user.email,
            displayName: user.displayName,
            uid: user.uid,
            hasAccess: isSuperDuper,
            superduper: isSuperDuper
          });
        }
      })
        .catch(error => {
          if (error.code === "permission-denied") {
            // If there is not a document setup on the shop for this user
            //  we would expect this error message.
            elmApp.ports.userLoggedIn.send({
              email: user.email,
              displayName: user.displayName,
              uid: user.uid,
              hasAccess: isSuperDuper,
              superduper: isSuperDuper
            });
            return;
          }
          elmApp.ports.firebaseError.send({
            errorMessage: error.message
          });
          console.error("Error updating document: ", error);
        });
    });
}



elmApp.ports.makeSuperDuperUser.subscribe(email => {
  const addSuperDuperRole = functions.httpsCallable("addSuperDuperRole");
  addSuperDuperRole({
    email: email
  }).then(result => {
    console.log("addSuperDuperRole result", result)
  });
});

elmApp.ports.changePassword.subscribe(data => {
  const user = firebase.auth().currentUser;
  const newPassword = data.newPassword;
  user.updatePassword(newPassword).then(() => {
    elmApp.ports.passwordChanged.send();
  }).catch(error => {
    console.error("new password error", error);

    if (error.code === "auth/requires-recent-login") {
      elmApp.ports.loginAgain.send(null);
    }
    else {
      elmApp.ports.firebaseError.send({
        errorMessage: error.message
      });
    }
  });

});

elmApp.ports.requestChairs.subscribe( () => {
  const query = shopsRef.doc(shopDocId).collection("chairs").limit(50);
  query.onSnapshot({
    next: chairsSnapshot => {
      const chairSnapshots = [];
      chairsSnapshot.forEach(chair => {
        const chairSnapshot = chair.data();
        chairSnapshot.uid = chair.id;
        chairSnapshots.push(chairSnapshot);
      });
      elmApp.ports.chairsUpdate.send(chairSnapshots);
    }
  });
});

elmApp.ports.updateChair.subscribe( chairData => {
  shopsRef.doc(shopDocId).collection("chairs").doc(chairData.uid).update({
    available: chairData.available,
    taken: chairData.taken,
    barberName: chairData.barberName,
  });

  shopsRef.doc(shopDocId).update({
    shopFloorLastUpdate: firebase.firestore.Timestamp.now()
  })
    .catch(function (error) {
      elmApp.ports.firebaseError.send({
        errorMessage: error.message
      });
      console.error("Error updating document: ", error);
    });
});

const updateShopHtml = () => {
  const updateShopHtml = functions.httpsCallable("updateShopHtml");
  updateShopHtml({
    shopDocId: shopDocId
  }).then(result => {
    console.log("updateShopHtml result", result)
  })
};

elmApp.ports.requestShopFloorLastUpdatedString.subscribe( shopFloorLastUpdated => {
  const str = formatDistance(new Date(shopFloorLastUpdated * 1000), Date.now());
  elmApp.ports.shopFloorLastUpdatedString.send(str);
});

elmApp.ports.requestShopUsers.subscribe( shopData => {
  const accessRequestsQuery = firestore.collection(`/shops/${shopData.shopUid}/accessRequests`).limit(50)
  accessRequestsQuery.onSnapshot({
    next: accessRequestsSnapshot => {
      accessRequestsSnapshot.forEach(accessRequest =>
        elmApp.ports.user.send(accessRequest.data())
      )
    }
  });

  const shopsUsersQuery = firestore.collection(`/shops/${shopData.shopUid}/users`).limit(50)
  shopsUsersQuery.onSnapshot({
    next: shopUsersSnapshopt => {
      shopUsersSnapshopt.forEach(shopUser =>
        elmApp.ports.user.send(shopUser.data())
      )
    }
  })
});

elmApp.ports.requestAccess.subscribe(requestData => {
  const requestAccessToShop = functions.httpsCallable("requestAccessToShop");
  requestAccessToShop({
    shopUid: requestData.shopUid
  }).then(result => {
    console.log("requestAccessToShop result", result)
  });
});

elmApp.ports.processAccessRequest.subscribe(data => {
  if (data.grantAccess) {
    firestore.doc(`/shops/${shopDocId}/users/${data.userUid}`).set({
      hasAccess: true
    })
  }
  firestore.doc(`/shops/${shopDocId}/accessRequests/${data.userUid}`).delete();
});
